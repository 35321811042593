<template>
  <div id="Role">
    <!-- 组织架构 角色 -->
    <!-- 抽屉模式 -->
    <el-drawer title="关联权限"
               :visible.sync="drawer"
               :direction="direction"
               :before-close="handleClose"
               size="40%"
               wrapperClosable
               append-to-body>
      <span @click="updatePower"
            class="update">更新</span>
      <el-tree :data="jsonRolePowerList"
               show-checkbox
               node-key="id"
               :default-expand-all='false'
               :expand-on-click-node="false"
               ref="tree">
        <!-- :default-checked-keys=""> -->
        <span class="custom-tree-node"
              slot-scope="{ node, jsonRolePowerList }">
          <span>{{ node.label }}</span>
          <span>{{ jsonRolePowerList }}</span>
        </span>
      </el-tree>
    </el-drawer>

    <!-- Dialog 对话框 S-->
    <el-dialog :title="openType === 'add' ? '新增角色' : '编辑角色'"
               :visible.sync="isShowDialogVisible">
      <CommonForm :form="openForm"
                  :formLabel="openFormLabel"
                  inline></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="cancelPowerForm()">取 消</el-button>
        <el-button type="primary"
                   @click="subPowerForm(openType, openForm)">确 定</el-button>
      </div>
    </el-dialog>
    <!-- Dialog 对话框  E-->

    <CommonManageMain>
      <template v-slot:header>
        <div>
          <el-button type="primary"
                     size="small"
                     icon="el-icon-circle-plus-outline"
                     @click="clickAddRole()">增加
          </el-button>
        </div>

        <CommonForm :form="searchForm"
                    :formLabel="searchFormLabel"
                    inline>
          <el-button type="primary"
                     @click="searchKeyword(searchForm)">搜索</el-button>
          <el-button type="text"
                     @click="resetFilter(searchForm)">重置筛选条件</el-button>
        </CommonForm>
      </template>

      <!-- 表格内容框 -->
      <template v-slot:content>
        <CommonTable :tableData="tableData"
                     :tableLabel="tableLabel"
                     :tableConfig="tableConfig"
                     @getPage="getPage"
                     style="height:600px">
          <el-table-column label="操作"
                           width="180">
            <template slot-scope="scope">
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="编辑"
                          placement="top">
                <span class="button"
                      @click="clickEditUser(scope.row)">编辑</span>
              </el-tooltip>
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="关联权限"
                          placement="top">
                <span class="button"
                      @click="clickEditRole(scope.row)">关联权限</span>
              </el-tooltip>
            </template>
          </el-table-column>
        </CommonTable>
      </template>
    </CommonManageMain>
  </div>
</template>

<script>
import { getRolePower, metRolePower, metRoleList, getRoleList, } from '@/api/organize.js'

import CommonForm from 'components/CommonForm.vue'
import CommonTable from 'components/CommonTable.vue'
import CommonManageMain from 'components/CommonManageMain.vue'

export default {
  components: {
    CommonForm,
    CommonTable,
    CommonManageMain
  },
  data () {
    return {
      // 抽屉栏目
      drawer: false,
      direction: 'rtl',

      // 对话框 Dialog 基础数据
      openType: 'add',
      isShowDialogVisible: false,

      // form表单 基础数据
      openForm: {
        name: '',
        description: '',
        status: ''
      },
      openFormLabel: [
        {
          model: 'name',
          label: '角色名称'
        },
        {
          model: 'description',
          label: '角色描述'
        },
        {
          model: 'status',
          label: '状态',
          type: 'switch'
        }
      ],

      // form表单 搜索数据
      searchForm: {
        keyword: ''
      },
      searchFormLabel: [
        {
          model: 'keyword',
          label: '角色名称',
        }
      ],

      // 表格数据
      tableData: [],
      tableLabel: [
        {
          prop: 'id',
          label: '角色ID',
        },
        {
          prop: 'name',
          label: '角色'
        },
        {
          prop: 'description',
          label: '角色描述'
        },
        {
          prop: 'status',
          label: '状态',
          type: 'stateSW'
        },
        {
          prop: 'update_time',
          label: '更新时间  '
        },
      ],
      tableConfig: {
        isShowLoading: true,
        // 改造状态列显示效果 el-switch

        page: 1,
        total: 30,
        // 加载状态
        loading: false,
        // 显示 选择多行数据
        selection: false,
      },

      // 全部数据
      allData: [],
      // 角色的权限列表
      RolePowerList: [],
      jsonRolePowerList: [],
      updatePowerID: '',//更新权限的ID
      PowerIDarry: [],
    }
  },
  mounted () {
    this.getRoleList()
  },
  watch: {
    // allData: function () {
    //   this.roleTableData()
    // }
    allData: function () {
      this.tableData = this.dataFormatDeal(
        this.allData,
        {
        },
        0
      )
    },
  },
  methods: {
    // 获取角色list数据
    // getRoleList (data) {
    //   metRoleList(data).then(res => {
    //     // name: "CEO"
    //     // description: "首席执行官"
    //     // status: 1
    //     console.log(res)
    //     this.allData = res.data.data.list
    //   })
    // },

    //   获取--管理人员列表数据
    getRoleList (data) {
      if (data) {
        getRoleList(data).then(res => {
          console.log(2332)
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.allData = DATA.data.list
            this.tableConfig.total = DATA.data.total
          }
        })
      } else {
        getRoleList({
          page: this.tableConfig.page,
          page_rows: 10
        }).then(res => {
          console.log(res)
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.allData = DATA.data.list

            this.tableConfig.total = DATA.data.total
            this.tableConfig.loading = false
          }

        })
      }
    },

    // 角色的权限 列表 get请求  ======再优化========
    getRolePowerList (id) {
      getRolePower(id).then(res => {
        // 数据格式
        // [
        //   {
        //     id: 1,
        //     label: '一级 name',
        //     children: [
        //       {
        //         id: 2,
        //         label: '二级 name',
        //         children
        //       }
        //     ]
        //   }
        // ]
        // this.RolePowerList
        this.RolePowerList = []
        const datas = res.data.data
        datas.forEach(item => {
          const cArr = []
          item.child.forEach(cItem => {
            const pArr = []
            cItem.permission.forEach(pItem => {
              pArr.push({
                id: pItem.id,
                label: pItem.name,
                group_id: pItem.permission_group_id,
                hold: pItem.hold
              })
            })

            cArr.push({
              id: cItem.id,
              label: cItem.name,
              parent_id: cItem.parent_id,
              children: pArr
            })
          })

          this.RolePowerList.push({
            id: item.id,
            label: item.name,
            parent_id: item.parent_id,
            children: cArr
          })
        })

        this.jsonRolePowerList = JSON.parse(JSON.stringify(this.RolePowerList))
        console.log('jsonRolePowerList', this.jsonRolePowerList)
      })
    },

    // 添加,编辑角色--发送到后端
    addMethodRoleList (method, args) {
      metRoleList(method, args).then(res => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.getRoleList()
        }
      })
    },

    // 更新角色权限
    metRolePower (args) {
      metRolePower(args).then(res => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.getRoleList()
        }
      })
    },

    // // 渲染table 数据
    // roleTableData () {
    //   this.tableData = []
    //   const datas = this.allData
    //   datas.forEach(item => {
    //     this.tableData.push({
    //       id: item.id,
    //       name: item.name,
    //       description: item.description,
    //       status: Boolean(item.status),
    //       update_time: item.update_time
    //     })
    //   })
    // },

    // 取消 dialog

    cancelPowerForm () {
      this.isShowDialogVisible = false
    },
    // 数据格式处理
    dataFormatDeal (arr, keyMap, copyInKeyMap) {
      // global 全局方法处理

      return this.globalDeepCloneData(arr, keyMap, copyInKeyMap)
    },
    // 确定提交 dialog
    subPowerForm (type, val) {
      let subVal = {}
      // JSON.parse(JSON.stringify(obj)) 利用深拷贝 不影响 原数据
      subVal = Object.assign({}, val)
      subVal.status = Number(subVal.status)

      type == 'add'
        ? this.addMethodRoleList('POST', subVal)
        : this.addMethodRoleList('PUT', subVal)
      this.isShowDialogVisible = false
    },

    // 新增角色
    clickAddRole () {
      this.openForm.name = ''
      this.openForm.description = ''
      this.isShowDialogVisible = true
    },

    // 编辑角色
    clickEditUser (row) {
      //  openForm: {
      //         name: '',
      //         description: '',
      //         status: true,
      //       },
      //       openFormLabel: [
      //         {
      //           model: 'name',
      //           label: '角色名称',
      //         },

      // const Nrow = JSON.parse(JSON.stringify(row))
      console.log(row)
      this.openForm.id = row.id
      this.openForm.name = row.name
      this.openForm.description = row.description
      this.openForm.status = row.status == true ? 1 : 0

      // const status = Number(Nrow.state)

      this.isShowDialogVisible = true
      this.openType = 'edit'
    },

    // 角色修改权限
    clickEditRole (val) {
      this.drawer = true
      this.updatePowerID = val.id
      this.getRolePowerList({ role_id: val.id })
    },
    //关闭抽屉
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
          this.updatePowerID = ''
        })
        .catch(_ => { })
    },
    //搜索关键字
    searchKeyword (searchForm) {
      const DATA = {
        search: searchForm.search
      }
      this.getRoleList(DATA)
    },
    //更新权限操作
    updatePower () {
      this.PowerIDarry = this.$refs.tree.getCheckedKeys()
      console.log(this.PowerIDarry)
      const DATA = {
        role_id: this.updatePowerID,
        permission_code: this.PowerIDarry
      }
      console.log(DATA)
      this.metRolePower(DATA)
    },
    //换页
    getPage () {
      console.log(this.tableConfig.page)
      this.getRoleList()
    }
  }
}
</script>

<style lang="scss" scoped>
#Role {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  height: 100%;
}
.el-tree {
  padding: 20px;

  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }
}
.button {
  margin-left: 10px;
  color: #0486fe;
  cursor: pointer;
}
.update {
  margin-left: 20px;
  color: #0486fe;
  cursor: pointer;
}
</style>
